<template>
  <div class="su-wrap">
    <vue-nav activeIndex='successfulCase'></vue-nav>
    <div class="ban"><img src="../assets/images/anlib.png" alt=""></div>
    <!-- 案例 -->
    <div class="swiper-container swiper2">
      <div class="swiper-wrapper">
        <div v-for="(item ,key) of swiperList" :key="key" class="swiper-slide">
          <img class="swiper-img" :src="item.imgUrl" alt="">
        </div>
      </div>
      <div class="swiper-button-prev swiper-button-black"></div>
      <div class="swiper-button-next swiper-button-black"></div>
    </div>
    <div class="se7">
      <h2>他们都在用</h2>
      <img class="rts" src="../assets/image/rtd.png" alt="">
    </div>
    <!-- 服务和保障 -->
    <div class="section8">
      <h2>服务和保障</h2>
      <ul class="set8list">
        <li>
          <h3>服务保障</h3>
          <p>保证不动老板的钱</p>
          <p>保证不动老板的顾客</p>
        </li>
        <li>
          <h3>售后保障</h3>
          <p>国内最专业保障，支持到店落地培训 </p>
          <p>一对一售后跟踪，客户满意度100%</p>
        </li>
        <li>
          <h3>技术保障</h3>
          <p>系统每周更新一次，</p>
          <p>技术保障接受商家需求反馈，</p>
          <p>不断升级迭代和完善产品</p>
        </li>
        <li>
          <h3>运营保障</h3>
          <p>分析诊断门店经营状况</p>
          <p>量身定制门店营销方案</p>
        </li>
        <li class="two2">
          <h3>安全保障</h3>
          <p>阿里云云端数据存储，</p>
          <p>签订商业安全协议，</p>
          <p>为用户数据提供金融级安全保障</p>
        </li>
        <li>
          <h3>效率保障</h3>
          <p>保证顾客管理、员工管理、</p>
          <p> 门店管理效率成倍提升</p>
        </li>
        <li>
          <h3>服务保障</h3>
          <p>保证去掉中间层，</p>
          <p>使管理成本大幅度降低</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import bnav from './components/b-nav'
export default {
  components: {
    'vue-nav': bnav,
  },
  data () {
    return {
      // 轮播，使用变量循环
      swiperList: [
        {
          id: '001',
          imgUrl: require('../assets/images/a1.jpg')
        },
        {
          id: '002',
          imgUrl: require('../assets/images/a2.jpg')
        },
        {
          id: '003',
          imgUrl: require('../assets/images/a3.jpg')
        },
      ],
    }
  },
  mounted () {
    new Swiper('.swiper2', {
      loop: true,
      // 如果需要分页器
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: 3500,
      pagination: '.swiper-pagination2',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    })
  },
  methods: {

  }
}
</script>
<style lang="scss">
@import "../assets/successful.scss";
</style>